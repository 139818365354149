import logo from "./img/logo.png";
function App() {
	return (
		<div className="app">
			<div className="toolbar">
				<div className="logo">
					<img src={logo} alt="logo"/>
				</div>
				<div className="logoStroke" />
				<marquee behavior="scroll" direction="left" scrollamount="5" className="roll">
					<label>股票代码是$TITS</label>
					<label>|</label>
					<label>1,000,000 $TITS</label>
					<label>|</label>
					<label>$TITS $TITS $TITS</label>
					<label>|</label>
					<label>需要牛奶?!!</label>
					<label>|</label>
					<label>TAX 2/2</label>
					<label>|</label>
					<label>购买$TITS</label>
					<label>|</label>
					<label>股票代码是$TITS</label>
					<label>|</label>
					<label>$TITS $TITS $TITS</label>
					<label>|</label>
					<label>需要牛奶?!!</label>
					<label>|</label>
					<label>购买$TITS</label>
				</marquee>
			</div>
			<div className="container">
				<div className="socials">
					<a href="https://twitter.com/ticker_is_tits" target="_blank">
						<img src="img/image01.jpg" />
					</a>
					<a href="#" target="_blank">
						<img src="img/image03.jpg" />
					</a>
					<a href="https://t.me/ticker_TITS" target="_blank">
						<img src="img/image04.png" />
					</a>
					<a href="#" target="_blank">
						<img src="img/image05.jpg" />
					</a>
				</div>
			</div>
		</div>
	);
}

export default App;
